import React from 'react'
import AccountSettings from '../../components/pages/AccountSettings'
import Layout from '../../layouts/layout'

const AccountPageWrapper = () => {
  if (typeof window === "undefined") return null
  return <Layout>
    <AccountSettings />
  </Layout>
}

export default AccountPageWrapper
